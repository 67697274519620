<template>
  <div class="subculture">
    <div class="sub-banner bg" />
    <div class="wrapper">
      <div class="bread">
        <div class="icon"><i class="el-icon-caret-right" /></div>
        <div class="text">您的位置：首页  <i>></i>  <span>企业文化</span></div>
      </div>
      <div class="sub-menu">
        <a href="/baseHome/about" title="公司简介">公司简介</a>
        <a href="/baseHome/culture" title="企业文化" class="on">企业文化</a>
        <a href="/baseHome/honor" title="荣誉资质">荣誉资质</a>
        <a href="/baseHome/cooperation" title="合作共赢">合作共赢</a>
        <a href="/baseHome/joinus" title="加入我们">加入我们</a>
      </div>
    </div>
    <div class="sub-title">企业文化</div>
    <div class="wrapper culture">
      <dl>
        <dt><img src="../../assets/images/cl1.jpg"></dt>
        <dd><h3>山水物联愿景</h3><span>用科技让物流产业更高效便捷</span></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/cl2.jpg"></dt>
        <dd><h3>山水物联使命</h3><span>打造智慧物流平台   构建智慧物流体系</span></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/cl3.jpg"></dt>
        <dd><h3>企业口号</h3><span>整合资源 搭建平台 便捷高效 互联互通</span></dd>
      </dl>
    </div>
    <div class="wrapper culture-img">
      <dl>
        <dt>企业精神文化</dt>
        <dd>服务 -- 企业生存的根本<br>
          规范 -- 企业发展的基石<br>
          效益 -- 企业发展的动力<br>
          创新 -- 企业竞争的保障</dd>
      </dl>
      <dl>
        <dt>经营理念</dt>
        <dd>服务为上<br>
          客户为宗<br>
          心系所托<br>
          物畅其流</dd>
      </dl>
      <div class="img"><img src="../../assets/images/climg.jpg"></div>
    </div>
    <div class="wrapper photo">
      <dl>
        <div class="demo-image__preview">
          <el-image
            style="width: 270px; height: 276px"
            :src="require('../../assets/images/tj1.jpg')"
            :preview-src-list="srcList1"
          />
        </div>
        <dd>团建名称1</dd>
      </dl>
      <dl>
        <div class="demo-image__preview">
          <el-image
            style="width: 270px; height: 276px"
            :src="require('../../assets/images/tj2.jpg')"
            :preview-src-list="srcList1"
          />
        </div>
        <dd>团建名称2</dd>
      </dl>
      <dl>
        <div class="demo-image__preview">
          <el-image
            style="width: 270px; height: 276px"
            :src="require('../../assets/images/tj3.jpg')"
            :preview-src-list="srcList1"
          />
        </div>
        <dd>团建名称3</dd>
      </dl>
      <dl>
        <div class="demo-image__preview">
          <el-image
            style="width: 270px; height: 276px"
            :src="require('../../assets/images/tj4.jpg')"
            :preview-src-list="srcList1"
          />
        </div>
        <dd>团建名称4</dd>
      </dl>
    </div>

    <!--  -->

    <!--  -->
  </div>

</template>

<script>

export default {
  name: '',
  components: {
  },
  data() {
    return {
      url: require('../../assets/images/tj1.jpg'),
      srcList1: [
        require('../../assets/images/tj1.jpg'),
        require('../../assets/images/tj2.jpg')
      ]
    }
  },

  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/sub.scss";
.subculture {
  .bg{background:url(./../../assets/images/aboutbg.jpg) }
  .culture{ overflow: hidden;
    dl{ float: left; width: 288px; margin: 0 45px 33px 0; border: #e9ebea solid 1px; padding: 40px 40px 30px;
      dt{ width: 288px; height: 174px; overflow: hidden;  text-align: center;}
      dt img{width: 288px; height: 174px;  transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
      &:hover dt img{width: 310px; height: 187px; margin-top: -6px;margin-left: -11px;}
      dd{ text-align: center;
        h3{ font-size: 22px; color: #333; font-weight: normal; padding:14px 0 10px; height: 43px; line-height: 43px; position: relative;}
        span{ font-size: 16px; color: #666; line-height: 27px;}
      }
    }
    dl:nth-child(3){margin-right: 0;}
  }
  .culture-img{background: #f6f6f6; overflow: hidden;
    dl{ float: left; width: 208px; padding: 21px 32px 0 34px;
      dt{ line-height: 68px; border-bottom: #d2d2d2 solid 1px; color: #333; font-size: 22px;}
      dd{ line-height: 44px; font-size: 16px; color: #666; padding-top: 17px;}
    }
    .img{ float: right;}
  }
  .photo{ padding: 74px 0 80px;overflow: hidden;
    dl{ float: left; width: 270px; height: 276px; overflow: hidden; position: relative; margin-right: 40px;
      // background:url(./../../assets/images/clp05.png) repeat-x;
      dd{ position: absolute; bottom: 120px; left: 0; width: 100%; overflow: hidden;  text-align: center; color: #fff; font-size: 20px;}
      img{width: 270px; height: 276px; margin-right: 40px; transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
      & img:hover{width: 290px; height: 296px; margin-left: -10px; margin-top:-10px;}
    }
    dl:nth-child(4){margin-right: 0;}
  }
}
</style>
